import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import "./LoginComp.css";
const LoginorSignup = () => {
  return (
    <>
      <Navbar />
     
      <Footer />
    </>
  );
};

export default LoginorSignup;
