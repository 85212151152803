import "./App.css";
import Routings from "./Routing/Routings";
function App() {
  return (
    <div className="App">
      <Routings />
    </div>
  );
}

export default App;
